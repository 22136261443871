import "./PageAbout.css";
import Bold from "./styleBold";

import { useState, useRef, useEffect, useCallback } from "react";

const About = () => {
  const paragraphRefs = useRef([]);

  return (
    <>
      <div className="about-section">
        <div className="about-block">
          <div className="about-paragraph">
            <div className="content">
              <div className="title">Who We Are</div>
              <div className="about-block-text">
                <p>
                  <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold>{" "}
                  is a non-profit organization founded in 2019 with the mission
                  of providing financial assistance to undergraduates and
                  parents struggling with college loan repayment. In the
                  landscape of higher education, where dreams are forged and
                  futures shaped, the journey often begins with a significant
                  investment – college education. For many, this investment
                  manifests in the form of student loans. However, for some
                  graduates and parents, the burden of these loans can become
                  overwhelming, overshadowing the joy of achievement with the
                  stress of financial strain. Our vision is fueled by our
                  passion for education and a commitment to ending the college
                  debt crisis.
                </p>
              </div>
            </div>
            <div className="image">
              <img src="./images/about/image101.webp" alt="" />
            </div>
          </div>

          <div className="about-paragraph">
            <div className="image">
              <img src="./images/about/image107.webp" alt="" />
            </div>
            <div className="content">
              <div className="title">Our Mission</div>
              <div className="about-block-text">
                <p>
                  Our mission is to provide financial assistance to
                  undergraduates and parents struggling with college loan
                  repayment. We aim to alleviate the burden of college debt,
                  empowering individuals to overcome these challenges. Together,
                  we strive to make education a source of empowerment, not
                  financial hardship.
                </p>
              </div>
            </div>
          </div>
          <div className="about-paragraph">
            <div className="content">
              <div className="title">Our Vision</div>
              <div className="about-block-text">
                <p>
                  At{" "}
                  <Bold> Bent Christy Graduate Assistance Program (BCGAP)</Bold>
                  , we envision a world where a person's access to higher
                  education is determined not by the size of their bank account,
                  but by the boundless capacity of their mind. Our dream is to
                  create a future where financial barriers or the weight of
                  student debt no longer prevent individuals from reaching their
                  full potential.
                </p>
              </div>
            </div>
            <div className="image">
              <img
                style={{ objectPosition: "0 41%" }}
                src="./images/about/image14.webp"
                alt=""
              />
            </div>
          </div>
          <div className="about-paragraph">
            <div className="image">
              <img src="./images/about/image11.webp" alt="" />
            </div>
            <div className="content">
              <div className="title">Value Statement</div>
              <div className="about-block-text">
                <ul>
                  <li>
                    <Bold>Equity</Bold>: We believe in equal access to education
                    for all, striving to remove financial barriers and create
                    opportunities for every student.
                  </li>
                  <li>
                    <Bold>Integrity</Bold>: We operate with transparency and
                    accountability, ensuring that resources are used efficiently
                    and ethically. We uphold the highest standards of integrity
                    and transparency in our operations, ensuring accountability
                    to our stakeholders.
                  </li>
                  <li>
                    <Bold>Impact</Bold>: We measure success by the positive
                    impact on individuals' lives and society as a whole.
                  </li>
                  <li>
                    <Bold>Empathy</Bold>: We understand and empathize with the
                    challenges faced by individuals burdened with student debt,
                    guiding our efforts with compassion and understanding.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
