import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (invalid) {
      setInvalid(false);
      setTimeout(() => {
        setInvalid(true);
      }, 0);
    }

    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        history.push("/admin-access");
      } else if (response.status === 401) {
        setInvalid(true);
      } else {
        const result = await response.json();
        setInvalid(true);
      }
    } catch (err) {
      setInvalid(true);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className={invalid ? "shake form-group" : "form-group"}>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setInvalid(false);
              }}
              required
            />
          </div>
          <div className={invalid ? "shake form-group" : "form-group"}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setInvalid(false);
              }}
              required
            />
          </div>

          <div className="invalid">
            {invalid && "Invalid username or password."}
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
