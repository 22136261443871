import "./footer.css";
import { ReactComponent as LogoSVGFooter } from "./assets/logo-equal.svg";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-col rights">
        <div className="logo">
          <a href="/">
            <LogoSVGFooter className="logo-svg-footer" />
          </a>
        </div>
        <div className="vision">
          Bent Christy Graduate Assistance Program (BCGAP) provides financial
          assistance to undergraduates and parents to allow them to have a
          debt-free life from the burden of college loans.
        </div>
        <div className="social-media">
          <img
            src="https://img.icons8.com/ios-filled/ffffff/100/facebook-new.png"
            alt="facebook-new"
          />
          <img
            src="https://img.icons8.com/ios-filled/100/ffffff/instagram-new--v1.png"
            alt="instagram-new--v1"
          />
          <img
            src="https://img.icons8.com/ios-filled/ffffff/100/linkedin.png"
            alt="linkedin"
          />
        </div>
      </div>

      <div className="footer-col">
        <div className="contacs">
          <div className="header">Links</div>
          <div className="body">
            <a href="/">Home</a>
            <a href="/about-bent-christy-graduate-assistance-program">
              About Us
            </a>
            <a href="/bent-christy-graduate-assistance-program-our-team">
              Our Team
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-college-students">
              BCGAP Undegraduate Repayment
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-parents">
              BCGAP Parent Repayment
            </a>
            <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
              BCGAP High School Senior Scholarship
            </a>
            <a href="/bent-christy-graduate-assistance-program-news-stories">
              News & Stories
            </a>
            <a href="/bent-christy-graduate-assistance-program-become-a-partner">
              Become a Partner
            </a>
            <a href="/bent-christy-graduate-assistance-program-volunteer-opportunities">
              Volunteer
            </a>
            <a href="/bent-christy-graduate-assistance-program-bcgap-store">
              Shop BCGAP
            </a>
            <a href="/donate-bent-christy-graduate-assistance-program">
              Donate
            </a>
            <a href="/bent-christy-graduate-assistance-program-contact-us">
              Contact Us
            </a>
          </div>
        </div>
      </div>
      <div className="footer-col">
        <div className="contacs">
          <div className="header"> Contact Us</div>
          <div className="body">
            <div className="email">
              <img
                style={{ opacity: ".7" }}
                src="https://img.icons8.com/parakeet-line/ffffff/96/new-post.png"
                alt="new-post"
              />
              email@example.com
            </div>
            <div className="phone">
              <img
                style={{ opacity: ".7" }}
                src="https://img.icons8.com/ios/ffffff/100/phone-message.png"
                alt="phone-message"
              />
              (414) 123-1516
            </div>
            <div className="message">
              <img
                style={{ opacity: ".7" }}
                src="https://img.icons8.com/parakeet-line/ffffff/96/chat.png"
                alt="chat"
              />
              <a href="/bent-christy-graduate-assistance-program-contact-us">
                Message us!
              </a>
            </div>
            <div>
              <a href="/admin-access">ADMIN ACCESS</a>
            </div>
          </div>
        </div>
      </div>
      <div className="rights-reserved">
        ©2019 by Bent Christy Graduate Assistance Program (BCGAP)
      </div>
    </div>
  );
};

export default Footer;
