import "./App.css";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import PageHome from "./PageHome";
import PageAbout from "./PageAbout";
import PageDonate from "./PageDonate";
import PageForUndergrads from "./PageForUndergrads-2";
import PageForUndergradsPrev from "./PageForStudents-2";
import PageForParents from "./PageForParents-2";
import PageVolunteer from "./PageVolunteer";
import PageForHighSchoolStudents from "./PageForHighSchoolStudents-2";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeNavigation from "./navigation";
import PaypalDonate from "./paypalDonate";
import Footer from "./footer";
import PaypalReturnPage from "./paypalReturnPage";
import BcgapStore from "./bcgapStore/bcgapStore";
import BcgapCart from "./bcgapStore/bcgapCart";
import ContactUs from "./contactUs";
import PageTeam from "./PageTeam";
import PageBecomePartner from "./PageBecomePartner";
import PageNewsStories from "./PageNewsStories";
import PageDonateStocks from "./PageDonateStocks";
import PageTestForms from "./PageTestForms";
import PageAdmin from "./PageAdminAccess";
import PageAdminIndividualHighSchool from "./PageAdminIndividualHighSchool";
import LoginPage from "./auth/login";
import checkAuth from "./utils/authUtils";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const authStatus = checkAuth();
    setIsAuthenticated(authStatus);
  }, []);

  return (
    <div className="App">
      <Router>
        <HomeNavigation />
        <Routes>
          <Route path="/" element={<PageHome />} />
          <Route
            path="/about-bent-christy-graduate-assistance-program"
            element={<PageAbout />}
          />
          <Route
            path="/donate-bent-christy-graduate-assistance-program"
            element={<PageDonate />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-for-college-students"
            element={<PageForUndergrads />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-for-parents"
            element={<PageForParents />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-for-high-school-students"
            element={<PageForHighSchoolStudents />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-volunteer-opportunities"
            element={<PageVolunteer />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-bcgap-store"
            element={<BcgapStore />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-bcgap-store-cart"
            element={<BcgapCart />}
          />
          <Route path="/paypal-donate" element={<PaypalDonate />} />
          <Route path="/paypal-return-page" element={<PaypalReturnPage />} />
          <Route
            path="/bent-christy-graduate-assistance-program-contact-us"
            element={<ContactUs />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-our-team"
            element={<PageTeam />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-become-a-partner"
            element={<PageBecomePartner />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-news-stories"
            element={<PageNewsStories />}
          />
          <Route
            path="/bent-christy-graduate-assistance-program-other-ways-to-donate"
            element={<PageDonateStocks />}
          />
          <Route path="/admin-access" element={<PageAdmin />} />

          <Route
            path="/show-applicant/high-school/:id/:name"
            element={<PageAdminIndividualHighSchool />}
          />

          <Route path="/login" element={<LoginPage />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
