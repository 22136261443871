import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import "./PageAdminAccess.css";

const AdminPage = () => {
  const [undergradData, setUndergradData] = useState();
  const [parentsData, setParentsData] = useState();
  const [highschoolData, setHighschoolData] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentGroup, setCurrentGroup] = useState();

  useEffect(() => {
    fetchAllHighschool();
  }, []);

  const fetchAllUndergrads = async () => {
    try {
      //const response = await fetch(
      //  "http://localhost:4000/api/undergrads/get-all"
      //);
      const response = await fetch("/api/undergrads/get-all");
      if (response.ok) {
        console.log("Undegrad applications fetched.");
        const data = await response.json();
        setUndergradData(data);
      } else {
        console.error("Applicants not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAllParents = async () => {
    try {
      //const response = await fetch("http://localhost:4000/api/parents/get-all");
      const response = await fetch("/api/parents/get-all");

      if (response.ok) {
        console.log("Parent applications fetched.");
        const data = await response.json();
        setParentsData(data);
      } else {
        console.error("Applicants not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAllHighschool = async () => {
    try {
      const response = await fetch("/api/high-school/get-all");

      if (response.ok) {
        console.log("High School applications fetched.");
        const data = await response.json();
        setHighschoolData(data);
      } else {
        console.error("Applicants not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteApplicant = async (currentGroup, currentIndex) => {
    try {
      const response = await fetch(
        `/api/${currentGroup}/delete-application/${currentIndex}`,

        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        console.log("High School application deleted.");
        setDeleteConfirm(false);
        fetchAllUndergrads();
        fetchAllParents();
        fetchAllHighschool();
      } else {
        console.error("Applicant not fetched.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = (whichGroup, index) => {
    setDeleteConfirm(true);
    setCurrentIndex(index);
    setCurrentGroup(whichGroup);
  };

  const handleConfirmDeletion = () => {
    deleteApplicant(currentGroup, currentIndex);
  };

  return (
    <div className="admin-page">
      <div
        className="delete-alert"
        style={{ display: deleteConfirm ? "flex" : "none" }}
      >
        <div> Are you sure you want to delete this candidate?</div>
        <div>
          <button onClick={handleConfirmDeletion}>Yes</button>{" "}
          <button onClick={() => setDeleteConfirm(false)}>No</button>
        </div>
      </div>
      <div className="list-high-school">
        <h1>Application Management</h1>
        <h2>High School Applications</h2>
        <table>
          <tbody>
            {highschoolData &&
              highschoolData.map((application, index) => {
                const name = `${application.firstName.toLowerCase()}-${application.lastName.toLowerCase()}`;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {application.firstName} {application.lastName || ""}
                    </td>
                    <td>
                      <div className="button-group">
                        <div className="view-btn">
                          <a
                            href={`/show-applicant/high-school/${application.highSchoolApplicant_id}/${name}`}
                          >
                            View
                          </a>
                        </div>
                        <div
                          className="delete-btn"
                          onClick={() => {
                            handleDelete(
                              "high-school",
                              application.highSchoolApplicant_id
                            );
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div style={{ marginTop: "2rem" }}></div>
        <h2>Undergraduate Applications</h2>
        <div style={{ marginTop: "2rem" }}></div>
        <h2>Parent Applications</h2>
      </div>
    </div>
  );
};

export default AdminPage;
