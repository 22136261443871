import "./homeSection5.css";
import { Link, useNavigate } from "react-router-dom";

const HomeSectionFive = () => {
  const navigate = useNavigate();

  return (
    <div
      className="home-main-sections"
      id="bcgap-apply-for-repayment-and-scholarship"
    >
      <div className="home-section-five">
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title"> </div>
          <div className="content">
            <div>
              Apply for Undergraduate Repayment & High School Senior
              Scholarship!
            </div>
          </div>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title">For High School Seniors</div>
          <div className="content">
            As you approach the exciting milestone of high school graduation and
            prepare for college, we offer high school senior scholarship and
            college loan repayment assistance programs to help you pay for
            college.
          </div>

          <a href="/bent-christy-graduate-assistance-program-for-high-school-students">
            <div className="apply">
              How to Apply{" "}
              <img
                src="https://img.icons8.com/pastel-glyph/64/fab900/circled-right.png"
                alt="apply-link"
              />
            </div>
          </a>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title">For Undergraduate Students </div>
          <div className="content">
            Are you having difficulty repaying your college loans? We provide
            financial assistance specifically for graduates who completed their
            degrees within four years and are experiencing repayment challenges.
          </div>
          <a href="/bent-christy-graduate-assistance-program-for-college-students">
            <div className="apply">
              How to Apply{" "}
              <img
                src="https://img.icons8.com/pastel-glyph/64/00c8dc/circled-right.png"
                alt="apply-link"
              />
            </div>
          </a>
        </div>
        <div className="home-section-five-col">
          <div className="overlay"></div>
          <div className="title">For Parents</div>
          <div className="content">
            Are you struggling to repay the loans taken out for your child's
            education? Bent Christy Graduate Assistance Program (BCGAP) provides
            financial assistance to parents facing college loan repayment
            hardships.
          </div>
          <a href="/bent-christy-graduate-assistance-program-for-parents">
            <div className="apply">
              How to Apply
              <img
                src="https://img.icons8.com/pastel-glyph/64/e81212/circled-right.png"
                alt="apply-link"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionFive;
