import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const PageTestForms = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    applicationEssay: null,
  });
  const [loginInfo, setLoginInfo] = useState({
    admin_name: "",
    admin_password: "",
  });
  const [loggedIn, setLoggedIn] = useState(false);

  const [applicants, setApplicants] = useState([]);

  useEffect(() => {
    const token = sessionStorage.getItem("token") || null;
    if (token && !isTokenExpired(token)) {
      setLoggedIn(true);
    }

    const fetchAllApplicants = async () => {
      try {
        const response = await fetch(
          "http://localhost:4000/api/get-all-high-school"
        );

        if (response.ok) {
          console.log("Applicants fetched.");
          const data = await response.json();
          setApplicants(data);
        } else {
          console.error("Applicants not fetched.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAllApplicants();
  }, []);

  // LOGIN

  function isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp < currentTime;
    } catch (e) {
      return true;
    }
  }

  const handleLoginChange = (event) => {
    const { name, value } = event.target;
    setLoginInfo({
      ...loginInfo,
      [name]: value,
    });
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();

    const loginDataToSubmit = {
      admin_name: loginInfo.admin_name,
      admin_password: loginInfo.admin_password,
    };

    try {
      const response = await fetch("http://localhost:4000/api/login-verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginDataToSubmit),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        console.log("Login submitted successfully");
        sessionStorage.setItem("token", token);
        setLoggedIn(true);
      } else if (response.status === 401) {
        console.log("Login failed");
        sessionStorage.removeItem("token");
        setLoggedIn(false);
      } else {
        console.log("Login submission failed");
        setLoggedIn(false);
        sessionStorage.removeItem("token");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // ADD NEW FORM
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create FormData object to handle form submission
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("firstName", formData.firstName);
    formDataToSubmit.append("email", formData.email);
    formDataToSubmit.append("applicationEssay", formData.applicationEssay);

    try {
      const response = await fetch(
        "http://localhost:4000/api/submit-new-application-high-school/",
        {
          method: "POST",
          body: formDataToSubmit,
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully");
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {" "}
      <div className="main" style={{ height: "20vh", paddingTop: "10rem" }}>
        <form onSubmit={handleSubmit}>
          <input
            style={{ margin: "1rem" }}
            type="text"
            name="firstName"
            placeholder="first name"
            value={formData.firstName}
            onChange={handleChange}
          />
          <input
            style={{ margin: "1rem" }}
            type="email"
            name="email"
            placeholder="email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            style={{ margin: "1rem" }}
            type="file"
            name="applicationEssay"
            onChange={handleFileChange}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
      <br />
      <br />
      <div style={{ height: "100%", padding: "2rem" }}>
        {" "}
        <h4>LOGIN</h4>{" "}
        <form onSubmit={handleSubmitLogin}>
          <input
            type="text"
            placeholder="Login"
            name="admin_name"
            value={loginInfo.admin_name}
            onChange={handleLoginChange}
          />
          <input
            type="password"
            name="admin_password"
            placeholder="Password"
            value={loginInfo.admin_password}
            onChange={handleLoginChange}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
      {loggedIn ? (
        <div style={{ height: "100%", padding: "2rem" }}>
          {applicants &&
            applicants.map((applicant, index) => {
              const applicationEssay =
                JSON.parse(applicant.applicationEssay) || "";
              return (
                <div key={index}>
                  {applicant.firstName} - {applicant.lastName} -{" "}
                  {applicant.email} -
                  {applicationEssay ? (
                    <a
                      href={`http://localhost:4000/uploads/${applicationEssay.filename}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download application essay
                    </a>
                  ) : (
                    <span>No file found.</span>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <div style={{ height: "100%", padding: "2rem" }}>
          You don't have the permissions to access this information.
        </div>
      )}
    </>
  );
};

export default PageTestForms;
