import "./storeTop.css";
import { useLocation } from "react-router-dom";

const StoreTop = () => {
  const location = useLocation();

  const backToStore = () => {
    switch (location.pathname) {
      case "/bent-christy-graduate-assistance-program-bcgap-store-cart":
        return "visible";
      default:
        return "hidden";
    }
  };

  return (
    <div className="store-top">
      <div className="store-header">
        Shop BCGAP t-shirts to end college debt!
      </div>
      <div className="store-cart">
        <a
          href="/bent-christy-graduate-assistance-program-bcgap-store"
          style={{ visibility: backToStore() }}
        >
          <span>
            <img
              src="https://img.icons8.com/ios/100/long-arrow-left.png"
              alt="long-arrow-left"
            />
          </span>
          <span>Back to the store</span>
        </a>
        <a href="/bent-christy-graduate-assistance-program-bcgap-store-cart">
          <span>Cart</span>
          <img
            src="https://img.icons8.com/sf-regular-filled/27313a/96/shopping-cart.png"
            alt="shopping-cart"
          />
          <div className="shopping-qty">0</div>
        </a>
      </div>
    </div>
  );
};

export default StoreTop;
