import "./bcgapStore.css";
import { useState, useEffect } from "react";
import bcgapStoreItems from "./bcgapStoreItems";

const BcgapStore = () => {
  const [countCart, setCountCart] = useState(0);
  const [itemQuantities, setItemQuantities] = useState(
    bcgapStoreItems.map(() => 1)
  );
  const [cart, setCart] = useState([]);
  const [itemsInStore, setItemsInStore] = useState(0);

  useEffect(() => {
    const tabs = document.querySelectorAll(".shop-tab");
    tabs[0].style.setProperty("--before-width", "100%");
  }, []);

  const shopMugs = [
    { name: "BCGAP Mug", price: "10.00", image: "./store-images/mug3.png" },
    { name: "BCGAP Mug", price: "12.00", image: "./store-images/mug1.png" },
    { name: "BCGAP Mug", price: "12.00", image: "./store-images/mug2.png" },
  ];

  const shopShirts = [
    {
      name: "BCGAP T-Shirt",
      price: "25.00",
      image: "./store-images/shirt10.png",
    },
    {
      name: "BCGAP T-Shirt",
      price: "25.00",
      image: "./store-images/shirt11.png",
    },
    {
      name: "BCGAP T-Shirt",
      price: "25.00",
      image: "./store-images/shirt12.png",
    },
  ];

  const shopBags = [
    {
      name: "BCGAP Tote Bag",
      price: "25.00",
      image: "./store-images/bag1.png",
    },
    {
      name: "BCGAP Tote Bag",
      price: "13.00",
      image: "./store-images/bag2.png",
    },
    {
      name: "BCGAP Tote Bag",
      price: "13.00",
      image: "./store-images/bag3.png",
    },
  ];

  const storeItemsToSell = [shopMugs, shopShirts, shopBags];

  const handleShowStoreItems = (item) => {
    setItemsInStore(item);
    const tabs = document.querySelectorAll(".shop-tab");
    tabs.forEach((tab) => {
      tab.style.setProperty("--before-width", "0%");
    });

    if (tabs[item]) {
      tabs[item].style.setProperty("--before-width", "100%");
    }
  };

  return (
    <div className="store-section">
      <div className="header"> Shop BCGAP to end college debt!</div>
      <div className="shop-tabs">
        <div className="shop-tab-header">CATEGORIES</div>
        <div className="shop-tab" onClick={() => handleShowStoreItems(0)}>
          Mugs
        </div>
        <div className="shop-tab" onClick={() => handleShowStoreItems(1)}>
          Shirts
        </div>
        <div className="shop-tab" onClick={() => handleShowStoreItems(2)}>
          Bags
        </div>
      </div>
      <div className="store">
        <div className="products">
          {storeItemsToSell[itemsInStore].map((item, index) => (
            <div key={index} className="store-item-card">
              <div className="store-item-image">
                <img src={`${item.image}`} alt="card-item-image" />
              </div>
              <div className="store-item-description">
                <div className="store-name-price">
                  <div className="store-item-title">{item.name}</div>
                  <div className="store-item-price">
                    $ {item.price} + shipping
                  </div>
                </div>
                <a href="" className="buy-btn">
                  <img
                    src="https://img.icons8.com/sf-regular/96/buy.png"
                    alt="buy"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BcgapStore;
