import { useState, useEffect } from "react";
import Bold from "./styleBold";
import { useParams } from "react-router-dom";
import "./PageAdminIndividualHighSchool.css";

const PageAdminIndividualHighSchool = () => {
  const { id } = useParams();
  const [currentApplicant, setCurrentApplicant] = useState();
  //const baseUrl = "http://localhost:4010";
  //const baseUrl = "http://82.197.92.74:5005";
  const baseUrl = "https://bcgap-test.nchernysheva.com";

  useEffect(() => {
    const fetchApplicant = async () => {
      try {
        const response = await fetch(`/api/high-school/get-individual/${id}`);

        if (response.ok) {
          console.log("An applicant fetched.");
          const data = await response.json();
          setCurrentApplicant(data);
        } else {
          console.error("Applicant not fetched.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchApplicant();
    console.log(currentApplicant);
  }, []);

  return (
    <div className="individual-applicant-page">
      <div className="return">
        <a href="/admin-access">
          <img
            src="https://img.icons8.com/ios/100/long-arrow-left.png"
            alt="long-arrow-left"
          />
          <div>Return to all applicants</div>{" "}
        </a>
      </div>
      <table>
        <tbody>
          {currentApplicant && (
            <>
              <tr>
                <td className="name">
                  <Bold>Name: </Bold>
                </td>
                <td className="name">
                  {currentApplicant[0].firstName} {currentApplicant[0].lastName}
                </td>
              </tr>
              <tr>
                <td>
                  <Bold>Email: </Bold>
                </td>
                <td>{currentApplicant[0].email}</td>
              </tr>
              <tr>
                <td>
                  <Bold>Address: </Bold>
                </td>
                <td>{currentApplicant[0].address}</td>
              </tr>
              <tr>
                <td>
                  <Bold>High School Attended: </Bold>
                </td>
                <td>{currentApplicant[0].highSchoolAttended}</td>
              </tr>
              <tr>
                <td>
                  <Bold>Year Graduated: </Bold>
                </td>
                <td>{currentApplicant[0].yearGraduated}</td>
              </tr>
              <tr>
                <td>
                  <Bold>Citizenship: </Bold>
                </td>
                <td>{currentApplicant[0].citizenship}</td>
              </tr>
              <tr>
                <td>
                  <Bold>CollegeAccepted: </Bold>
                </td>
                <td>{currentApplicant[0].collegeAccepted}</td>
              </tr>
              <tr>
                <td>
                  <Bold>Academic Records: </Bold>
                </td>
                <td>
                  <a
                    href={`/uploads/${currentApplicant[0].academicRecords}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <Bold>Recommendation Letter: </Bold>
                </td>
                <td>
                  <a
                    href={`/uploads/${currentApplicant[0].recommendationLetter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <Bold>Application Essay: </Bold>
                </td>
                <td>
                  <a
                    href={`/uploads/${currentApplicant[0].applicationEssay}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <Bold>Comments: </Bold>
                </td>
                <td>{currentApplicant[0].comments}</td>
              </tr>

              <tr>
                <td className="pdf-download" colspan="2">
                  <a
                    href={`/api/high-school/download-pdf/${currentApplicant[0].highSchoolApplicant_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download PDF
                  </a>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PageAdminIndividualHighSchool;
