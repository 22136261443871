import "./homeSection4.css";
import { useState } from "react";
import Bold from "./styleBold";

const HomeSectionFour = () => {
  const windowWidth = window.innerWidth;
  const [contentHeight, setContentHeight] = useState(false);

  const handleShowMore = () => {
    setContentHeight(!contentHeight);
  };
  const breakLine = () => {
    if (windowWidth < 1000) {
      return (
        <>
          <div style={{ padding: "0.5em 0" }}> </div>
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <div className="home-main-sections section-four">
      <div className="home-section-four-block-wrapper-full-screen">
        <div className="home-section-four-block-wrapper">
          <div className="heading">
            Who We Are <br />& What We Do{" "}
          </div>
          <div className="image-wrapper">
            <div className="image-container">
              <img
                style={{ objectFit: "cover" }}
                src="./images/home/headshot1.jpg"
                alt="bent-christy-team-member"
              />
            </div>
            <div className="image-container">
              <img
                src="./images/home/headshot2.webp"
                alt="bent-christy-team-member"
              />
            </div>
          </div>
          <div className="division-line"></div>
          <div
            className="content"
            style={{ maxHeight: contentHeight && "100%" }}
          >
            <Bold> Bent Christy Graduate Assistance Program (BCGAP)</Bold> is a
            non-profit organization founded in 2019 with the mission of
            providing financial assistance to undergraduates and parents
            struggling with college loan repayment. In the landscape of higher
            education, where dreams are forged and futures shaped, the journey
            often begins with a significant investment – college education. For
            many, this investment manifests in the form of student loans.
            However, for some graduates and parents, the burden of these loans
            can become overwhelming, overshadowing the joy of achievement with
            the stress of financial strain. Our vision is fueled by her passion
            for education and a commitment to ending the college debt crisis.
          </div>
          <div className="arrow-down-show-more" onClick={handleShowMore}>
            <img
              style={{ transform: contentHeight && `rotate(180deg)` }}
              src="https://img.icons8.com/ios-filled/8a8888/100/sort-down.png"
              alt="bent-christy-about-us-show-more"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionFour;
