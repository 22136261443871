import "./PageForHighSchoolStudents-2.css";
import Bold from "./styleBold";
import Red from "./styleRed";
import { useState, useRef } from "react";

const PageForHighSchoolStudents = () => {
  const applicationAboutRef = useRef(null);
  const applicationFormRef = useRef(null);
  const [showContent, setShowContent] = useState([false, false, false]);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const academicRecordsRef = useRef(null);
  const applicationEssayRef = useRef(null);
  const recommendationLetterRef = useRef(null);
  const checkboxRef = useRef(null);
  const [isOpenYearGraduated, setIsOpenYearGraduated] = useState(false);
  const [isOpenCitizenship, setIsOpenCitizenship] = useState(false);
  const [application, setApplication] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    highSchoolAttended: "",
    yearGraduated: "",
    citizenship: "",
    collegeAccepted: "",
    academicRecords: null,
    applicationEssay: null,
    recommendationLetter: null,
    comments: "",
  });

  const handleShowContent = (index) => {
    setShowContent((prevShowContent) =>
      prevShowContent.map((item, i) => (i === index ? !item : item))
    );
  };

  const handleChange = (e) => {
    const { name, type, value, files } = e.target;

    if (type === "file") {
      setApplication((prevState) => ({
        ...prevState,
        [name]: files[0], // For file inputs
      }));
    } else {
      setApplication((prevState) => ({
        ...prevState,
        [name]: value, // For text inputs
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.keys(application).forEach((key) => {
      if (application[key] !== null) {
        formData.append(key, application[key]);
      }
    });

    fetch("/api/high-school/submit-new-application", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setSubmissionSuccess(true);
        setApplication({
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          highSchoolAttended: "",
          yearGraduated: "",
          citizenship: "",
          collegeAccepted: "",
          academicRecords: null,
          applicationEssay: null,
          recommendationLetter: null,
          comments: "",
        });

        if (academicRecordsRef.current) academicRecordsRef.current.value = "";
        if (applicationEssayRef.current) applicationEssayRef.current.value = "";
        if (recommendationLetterRef.current)
          recommendationLetterRef.current.value = "";
        if (checkboxRef.current) {
          checkboxRef.current.checked = false;
        }

        setTimeout(() => {
          fetch("/api/send/send-email-new-application", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message: "New application received",
            }),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return response.text().then((text) => {
                  throw new Error(text);
                });
              }
            })
            .then((data) => {
              console.log("Success:", data);
            })
            .catch((error) => {
              console.error("Error triggering email:", error);
              alert("An error occurred while triggering the email.");
            });
        }, 5000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="high-school-page funding-page">
      <div
        className="submission-success"
        style={{ display: submissionSuccess ? "flex" : "none" }}
      >
        <div>Thank you for submitting your application!</div>
        <button
          onClick={() => {
            setSubmissionSuccess(false);
          }}
        >
          OK
        </button>
      </div>
      <div className="funding-heading">
        Bent Christy Graduate Assistance Program (BCGAP) Foundation Scholarship
        Program
      </div>
      <div className="funding-content">
        <div className="text-image">
          <div className="image">
            <img
              src="./images/for-students/h-student2.webp"
              alt="high-school-student-photo"
            />
          </div>
          <div className="text">
            <div className="paragraph">
              Great job on reaching the exciting milestone of high school
              graduation!
            </div>
            <div className="paragraph">
              As you begin the next chapter of your education, we want to inform
              you how{" "}
              <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold> can
              assist with college loan repayment. We recognize that managing
              student loan repayments after graduation can be stressful. BCGAP
              believes that financial circumstances should never be a barrier to
              pursuing a college education. That is why we offer financial
              assistance to qualified graduates who completed their degrees
              within four years and are struggling with loan repayment. Here is
              how we can help as you embark on the next phase of your
              educational journey.
            </div>{" "}
            <div className="paragraph">
              Our work is fueled by a passion for education, and being the
              change we need to see to end the college debt crisis. Learn more
              about our mission, our vision, and the opportunity how you can
              empower and help lighten the burden of college debt for you and
              the next generation.
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "2rem" }}
          className="subheading"
          ref={applicationAboutRef}
          onClick={() => handleShowContent(0)}
        >
          Scholarship Overview{" "}
          <img
            style={{
              transform: showContent[0] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/00c8dc/100/expand-arrow--v1.png"
            alt="show-about-scholarship"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[0] ? "block" : "none" }}
        >
          <div>
            The <Bold>Bent Christy Graduate Assistance Program (BCGAP)</Bold>{" "}
            scholarship is open to high school seniors and recent graduates
            (within one year of graduation) who have a GPA of 2.5 or higher and
            plan to attend a four-year university. Scholarship recipients are
            chosen based on their proven leadership skills, and dedication to
            service in their local communities. Preference is given to
            applicants from single-parent households who show a strong desire to
            create positive change in the world.
            <div style={{ marginTop: "1.5rem" }}></div>
            $1,000 Bent Christy Graduate Assistance Program (BCGAP)
            <div style={{ marginTop: "1.5rem" }}></div>
            <Bold> Scholarship Current Deadline:</Bold> July 31, 2024{" "}
          </div>
        </div>

        <div
          className="subheading"
          ref={applicationFormRef}
          onClick={() => handleShowContent(2)}
        >
          Application Form
          <img
            style={{
              transform: showContent[2] ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src="https://img.icons8.com/ios-filled/00c8dc/100/expand-arrow--v1.png"
            alt="show-application-form"
          />
        </div>
        <div
          className="application-content"
          style={{ display: showContent[2] ? "block" : "none" }}
        >
          <div>
            Please fill out the form below to submit your scholarship
            application.
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="first-name">
              First Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="first-name"
              name="firstName"
              onChange={handleChange}
              value={application.firstName}
              required
            />
            <label htmlFor="last-name">
              Last Name <Red>*</Red>
            </label>
            <input
              type="text"
              id="last-name"
              name="lastName"
              onChange={handleChange}
              value={application.lastName}
              required
            />
            <label htmlFor="email">
              Email <Red>*</Red>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              value={application.email}
              required
            />
            <label htmlFor="address">
              Address <Red>*</Red>
            </label>
            <input
              type="address"
              id="address"
              name="address"
              onChange={handleChange}
              value={application.address}
              required
            />
            <label htmlFor="high-school-attended">
              High School Attended <Red>*</Red>
            </label>
            <input
              type="text"
              id="high-school-attended"
              name="highSchoolAttended"
              onChange={handleChange}
              value={application.highSchoolAttended}
              required
            />
            <label htmlFor="another-field">
              Year of Graduation <Red>*</Red>
            </label>

            <div className="year-graduated-dropdown">
              <input
                type="text"
                id="year-of-graduation"
                name="yearGraduated"
                onChange={handleChange}
                onClick={() => {
                  setIsOpenYearGraduated(!isOpenYearGraduated);
                  setIsOpenCitizenship(false);
                }}
                value={application.yearGraduated}
                required
                readOnly
              />
              {isOpenYearGraduated && (
                <div className="dropdown-content">
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          yearGraduated: "2024",
                        }),
                        setIsOpenYearGraduated(!isOpenYearGraduated)
                      )
                    }
                  >
                    2024
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          yearGraduated: "2025",
                        }),
                        setIsOpenYearGraduated(!isOpenYearGraduated)
                      )
                    }
                  >
                    2025
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          yearGraduated: "2026",
                        }),
                        setIsOpenYearGraduated(!isOpenYearGraduated)
                      )
                    }
                  >
                    2026
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          yearGraduated: "2027",
                        }),
                        setIsOpenYearGraduated(!isOpenYearGraduated)
                      )
                    }
                  >
                    2027
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          yearGraduated: "2028",
                        }),
                        setIsOpenYearGraduated(!isOpenYearGraduated)
                      )
                    }
                  >
                    2028
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          yearGraduated: "2029",
                        }),
                        setIsOpenYearGraduated(!isOpenYearGraduated)
                      )
                    }
                  >
                    2029
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          yearGraduated: "2030",
                        }),
                        setIsOpenYearGraduated(!isOpenYearGraduated)
                      )
                    }
                  >
                    2030
                  </div>
                </div>
              )}
            </div>
            <label htmlFor="citizenship">
              Citizenship
              <Red>*</Red>
            </label>

            <div className="citizenship-dropdown">
              <input
                type="text"
                id="citizenship"
                name="citizenship"
                onChange={handleChange}
                value={application.citizenship}
                onClick={() => {
                  setIsOpenCitizenship(!isOpenCitizenship);
                }}
                required
                readOnly
              />
              {isOpenCitizenship && (
                <div className="dropdown-content">
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          citizenship: "US citizen",
                        }),
                        setIsOpenCitizenship(!isOpenCitizenship)
                      )
                    }
                  >
                    US citizen
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          citizenship: "US national",
                        }),
                        setIsOpenCitizenship(!isOpenCitizenship)
                      )
                    }
                  >
                    US national
                  </div>
                  <div
                    onClick={() =>
                      setApplication(
                        (prevState) => ({
                          ...prevState,
                          citizenship: "US permanent resident",
                        }),
                        setIsOpenCitizenship(!isOpenCitizenship)
                      )
                    }
                  >
                    US permanent resident
                  </div>
                </div>
              )}
            </div>

            <label htmlFor="college-accepted">
              College Accepted <Red>*</Red>
            </label>
            <input
              type="text"
              id="college-accepted"
              name="collegeAccepted"
              onChange={handleChange}
              value={application.collegeAccepted}
              required
            />
            <label htmlFor="application-letter">
              Academic Records <Red>*</Red>
            </label>
            <input
              type="file"
              id="academic-records"
              name="academicRecords"
              accept=".pdf,application/pdf"
              onChange={handleChange}
              ref={academicRecordsRef}
              required
            />
            <label htmlFor="application-essay">
              Application Essay <Red>*</Red>
            </label>
            <input
              type="file"
              id="application-essay"
              name="applicationEssay"
              accept=".pdf,application/pdf"
              onChange={handleChange}
              ref={applicationEssayRef}
              required
            />
            <label htmlFor="recommendation-letters">
              Letter of Recommendation <Red>*</Red>
            </label>
            <input
              type="file"
              id="recommendation-letter"
              name="recommendationLetter"
              onChange={handleChange}
              accept=".pdf,application/pdf"
              ref={recommendationLetterRef}
              required
            />
            <label htmlFor="comments">Comments</label>
            <textarea
              type="text"
              id="comments"
              name="comments"
              onChange={handleChange}
              value={application.comments}
            />
            <label htmlFor="policy" className="policy">
              <input type="checkbox" name="policy" ref={checkboxRef} required />
              <span>
                By checking this box you are consent to being entered for a
                chance to win our Bent Christy Graduate Assistance Program
                (BCGAP) scholarship, processing of your Personal Information in
                Accordance with our <u>Privacy Policy</u>.
              </span>
            </label>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageForHighSchoolStudents;
